import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import TopBunerProduct from '../../components/top-buner/top-baner-product';
import OfficeAtMap from '../../components/office-on-map/office-on-map';
import ImagesView from '../../components/images-view/images-view';
import FormQuickOrder from '../../components/form-quick-oreder/form-quick-order';
import NubianGoatfeature from '../../images/goats/nubiskaya_poroda_koz/nubis-feature.jpg'
import NubianGoatGallery1 from '../../images/goats/nubiskaya_poroda_koz/kozy-nubiskie.jpg'
import NubianGoatGallery2 from '../../images/goats/nubiskaya_poroda_koz/nubiskie-kozliata.jpg'
import NubianGoatGallery3 from '../../images/goats/nubiskaya_poroda_koz/nubiskiy-kozel.jpg'
import NubianGoatGallery4 from '../../images/goats/nubiskaya_poroda_koz/nubiskiy-kozel-vistavka.jpg'
import NubianGoatGallery5 from '../../images/goats/nubiskaya_poroda_koz/nubiskiy-kozlenok.jpg'
import NubianGoatGallery6 from '../../images/goats/nubiskaya_poroda_koz/plemennaia-nubiskaya-koza.jpg'
import NubianGoatGallery7 from '../../images/goats/nubiskaya_poroda_koz/plemennaia-nubiskaya-koza.jpg'


const NubianePoroda = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Описание Нубийской породы коз', url:'#', status:true}]

    const indicators = [
      {name: 'Надой за 305 дней(л)', progress: 814, benchmark:1200},
      {name: 'Надой в день в среднем(л)', progress: 2.67, benchmark:4},
      {name: 'Жирность молока(%)', progress: 4.8, benchmark:6},
      {name: 'Белки(%)', progress: 3.7, benchmark:5},
      {name: 'Жир + белки(%)', progress: 8.5, benchmark:10},
      {name: 'Лактация дней', progress: 315, benchmark: 365},
      {name: 'Вес коз', progress: 45, benchmark:100},
      {name: 'Приплод', progress: 280, benchmark:300},
  ]

    const galleryImage = [
      {
        src: NubianGoatGallery1,
        width: 3,
        height: 2,
        alt: 'Нубийская порода коз',
        title: 'Нубийская порода коз'
      },
      {
        src: NubianGoatGallery2,
        width: 3,
        height: 2,
        alt: 'Нубийская порода коз',
        title: 'Нубийская порода коз'
      },
      {
        src: NubianGoatGallery3,
        width: 3,
        height: 2,
        alt: 'Нубийская порода коз',
        title: 'Нубийская порода коз'
      },
      {
        src: NubianGoatGallery4,
        width: 3,
        height: 2,
        alt: 'Нубийская порода коз',
        title: 'Нубийская порода коз'
      }
    ];
  return (
    <div>
        <SEO title="Нубийская порода коз: описание, фото, характеристики"
            description="Главной особенностью нубийской породы коз является ее уникальная внешность.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Описание Нубийская породы коз'}
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={'descrition-page'}
                      breadCrumbs={breadCramb}
                      indicators={indicators}>
            </TopBunerProduct>
        <Container fluid>
         <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify ">
                <div className="pl-md-5">
                    <h1 className="text-center text-md-left ">Нубийская порода коз</h1>
                    <p className="text-justify">
                    Нубийская (англо-нубийская) порода  коз является одной из самых древних ныне существующих пород. 
                    Помимо своего древнего происхождения она также считается двух целевой мясомолочной породой. 
                    Уникальность нубиек заключается в высокой жирности производимого ими молока, 
                    которая может достигать  от 5% до 8%  и отсутствии у него специфического козьего запаха. 
                    Поэтому принято считать  их идеальными для разведения в личном подсобном хозяйстве.
                    </p>
                <Row>
                  <Col md="12">
                    <h4>Оглавление:</h4>
                  </Col>
                  <ul className="pl-5">
                    <li><a href="#origin">Происхождение породы</a></li>
                    <li><a href="#description">Описание породы</a></li>
                    <li><a href="#productivity">Продуктивность Альпийских коз</a></li>
                    <li><a href="#care">Уход и содержание</a></li>
                    <li><a href="#diet">Рацион коз</a></li>
                    <li><a href="#dignity">Достоинства  породы</a></li>
                  </ul>
                </Row>
                </div>
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={NubianGoatfeature} className="w-100" style={{maxWidth:"500px"}}  
                                                            alt="Изображени Нубийской породы козы" 
                                                            title="Изображени Нубийской породы козы" />
              </Col>
            </Row>
        </Container>
        
         <Container fluid className="mt-3">
           <Row>
             <Col md="12" className="pl-md-5">
               <h2 id="origin">Происхождение породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
              Родом нубийские козочки из далёкой Южной Африки, а именно Намибии, именно там изначально была выведена эта порода,
               а свое гордое  название они получили в честь Нубийской пустыни большая часть которой находится на территории Судана. 
              </p>
            </Col>
            <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify" className="text-justify">
              Впоследствии на протяжении более 100 лет, английскими селекционерами путём скрещивания пород козлов из Индии, 
              Среднего Востока и Африки с молочными английскими козами была выведена нубийская порода коз. 
              Зачастую их также называют англо-нубийскими. Впервые официально нубийская порода коз была зарегистрирована в 1896 году. 
              В 1900 году нубийских коз начали разводить в Америке. В результате кропотливой работы, местными селекционерами порода 
              была улучшена и уже в 1906 году 40 нубийских козочек были официально зарегистрированы под названием Nubian. 
              В Россию  же первая партия была завезена лишь в 1999 году. 
              </p>
            </Col>
           </Row>
         </Container>
        <Container fluid className="mt-3">
          <h2 className="text-center">Фото коз Альпийской породы</h2>
          <ImagesView galleryImage={galleryImage} />
        </Container>
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="description">Описание породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
              Главной особенностью нубийской породы коз является ее уникальная внешность. Очень длинные свисающие уши 
              гораздо ниже своей морды и горбатый благородный "римский" профиль. 
              Окрас нубийских коз - это буйство красок от белого до черно-пятнистого. 
              Шерсть короткая блестящая и мягкая. 
              </p>
              <p className="text-justify">
                Они  являются одной из самых крупных пород коз, их высота в холке может достигать до 90 см. Вес взрослой самки колеблется в пределах 80 кг., а  вес козла может достигать аж 120 кг. Отличительными чертами также являются:  массивное тело, 
                при этом корпус длинный и узкий, длинные стройные ноги, тонкая длинная шея, у самок – большое вытянутое вымя с длинными сосками. 
              </p>
              <p className="text-justify">Козлята рождаются с белыми отметинами на теле, которые исчезают по мере взросления. Взрослые альпийские козлы достигают до 76 -80 см роста в холке  и 56-80 килограмм веса.  Для самок оптимальный вес около 60 кг рост в пределах 62-75 см.</p>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Шерсть у этих коз короткая, прямая с удлинением вдоль хребта. Для фермеров кто присматривает коз с хорошим выходом шерсти, 
                данная порода не подойдет. 
              </p>
              <p className="text-justify">
              Самки зачастую являются комолыми, козлы же наоборот должны быть с крепкими недлинными рогами. Нельзя не сказать о том, 
              что мясо и молоко нубийских коз  отличается отсутствием специфического запаха, что делает его уникальным и невероятно вкусным.
              </p>
              <p className="text-justify">
              Характером нубийская порода коз напоминает собак, они очень доброжелательны к людям знают многие команды, очень любопытны и игривы, 
              что делает их идеальными для разведения в личном  подсобном  хозяйстве.
              </p>
              <p className="text-justify">
                 Являются одной из самых плодовитых пород, в одном помете может быть до трех козлят.
              </p>
             </Col>
           </Row>
         </Container>
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="productivity">Продуктивность коз Нубийской породы</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Англо-нубийские козы  считаются  высокопродуктивной двух целевой мясомолочной породой. 
                Мясо этой породы является диетическим  продуктом. Так как отличительными чертами нубийцев является внушительный размер, 
                то можно себе представить какой мясной выход получается в итоге. 
                Мясо отличается от других пород коз своей сочностью и вкусом, 
                а также отсутствием козьего запаха.  пожилым людям, больным с сердечной и иммунной недостаточностью. 
              </p>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <h3>Удойность</h3>
              <p className="text-justify">
                После первого окота англо-нубиек удой молока составляет около 4 литров в сутки. Период лактации составляет около 300 дней в году,
                что  в совокупности дает годовые удои около 800-1200 литров. С каждым последующим окотом молочная продуктивность возрастает.
              </p>
              <p className="text-justify">
                Уникальность нубиек заключается в высокой жирности производимого ими молока, которая может достигать  от 5% до 8%  
                и отсутствии у него специфического козьего запаха. Содержание белка в молоке  около 4 %. 
                Вкусовые качества этого молока никакого не оставят равнодушными, по вкусу оно напоминает сливочный пломбир. 
                Из него производят сыры, йогурты, творог и тд.
              </p>
             </Col>
           </Row>
         </Container>
         <FormQuickOrder className="mt-3" />
         <Container fluid className="mt-5">
           <Row>
            <Col md="12" className="pl-md-5">
               <h2 id="care">Уход и содержание</h2>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
                Из за своего Африканского происхождения нубийские козы отлично переносят жару. 
                Если же планируется разведение в прохладном  или холодном климате, для содержания нубиек обязательно нужно чистое, 
                отапливаемое и хорошо вентилируемое помещение. Так же в нем должно быть достаточно света.  
              </p>
              <p className="text-justify">
                Нельзя допустить сквозняков и сырости иначе это может спровоцировать различные болезни. На пол укладывается подстилка из сена, 
                соломы и опилок. Так как англо-нубийская  порода коз не особо желает спать на полу для нее желательно установить спальные места. 
                Это делается  путем размещения по периметру помещения на  высоте около 40 см приколоченных к стенам широких досок. 
              </p>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
              <p className="text-justify">
              Допускается размещение небольшого помоста. В Зимнее время суток козам также положено гулять, как и в летнее. Поэтому рядом  
              с помещением где содержатся козы, желательно установить загон под навесом.  
              </p>
              <p className="text-justify">
                Не рекомендуется содержание с другими животными.
              </p>
             </Col>
           </Row>
         </Container>
         <Container fluid className="mt-5">
           <Row>
             <Col md="6" sm="12" className="pl-md-5">
               <h2 id="diet">Рацион коз</h2>
              <p className="text-justify">
                Для нубийской породы коз важно сбалансированное питание. Залог получения качественного молока-это соблюдение графика питания. 
                Рацион их должен состоять из:
              <ul>
                <li>- сено</li>
                <li>- овощей</li>
                <li>- зерновые концентрты</li>
              </ul>
              </p>
              <p className="text-justify">
                Необходимо обязательно давать витаминные подкормки, желательно концентрированные корма с премиксами для мясомолочных пород. 
                В зимнее время в питьевую воду добавляется соль , вода при этом должна быть теплой. Что касаемо летнего периода,  
                возле поилок раскладываются лизунцы. Мел должен даваться козочкам регулярно.  
              </p>
              <p className="text-justify">
                Желательно запастись различными видами веников. Охотнее всего нубийками поедается ива, орешник, сосна с зеленой корой. Важно не давать березовые, так как они провоцируют развитие заболеваний почек. 
              </p>
              <p className="text-justify">
                Для увлечения  надоев  козочкам дают  запаренный или размолотый овес и ячмень.
              </p>
              <p className="text-justify">
                Козлов производитель в период размножения нужно кормить бобовыми и злаковыми травами, премиксами, минеральными и витаминными комплексами.
              </p>
             </Col>
             <Col md="6" sm="12" className="pl-md-5">
             <h2 id="dignity">Достоинства и недостатки породы</h2>
             <h3>Достоинства породы:</h3>
              <ul>
                <li>- дружелюбность и покладистость породы не оставит равнодушным ни одного фермера</li>
                <li>- плодовитость- потомство могут давать до двух разв год. В одном помете может быть сразу три козленка. </li>
                <li>- высокопродуктивные качества молока и  мяса;</li>
                <li>- быстрый набор веса </li>
                <li>- продолжительный лактационный период</li>
              </ul>
              <h3>Недостатки породы:</h3>
              <ul>
                <li>Вислозадость</li>
                <li>Слабо развитая мышечная масса</li>
                <li>Вислозадость</li>
              </ul>
             </Col>
           </Row>
         </Container>
        <OfficeAtMap />
      </Layout>
    </div>
  );
};

export default NubianePoroda;